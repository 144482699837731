<template>
  <div class="content-wrapper">
    <div class="box">
      <h2 class="mb-3">
        {{ $t('activity-logs') }}
      </h2>
      <ActivityLogs :id-user="currentUser.id" />
    </div>
  </div>
</template>

<script>
import ActivityLogs from '@/components/utils/ActivityLogs.vue';

export default {
  name: 'UserActivity',
  components: { ActivityLogs },
  computed: {
    /** @returns {CytoUser} */
    currentUser() {
      return this.$store.state.currentUser.user;
    },
  },
};
</script>

<style scoped>
.content-wrapper {
  height: 100%;
}

.box {
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>
