<template>
  <BMessage v-if="error" type="is-danger" has-icon icon-size="is-small">
    <h2 class="mb-3">
      {{ $t('error') }}
    </h2>
    <p>{{ $t('unexpected-error-info-message') }}</p>
  </BMessage>
  <div
    v-else-if="formattedActions.length > 0"
    ref="listActions"
    @scroll="scrollHandler"
  >
    <div
      v-for="(month, idx) in formattedActions"
      :key="idx"
      :class="{
        'mt-4 border-t border-gray-1 pt-4': idx > 0,
      }"
    >
      <h3 class="weight-6">
        {{ month.refDate | date('MMMM YYYY') }}
      </h3>
      <div v-for="(day, idx2) in month.days" :key="idx2">
        <h4 class="p-4 size-12 weight-6 uppercase color-gray-3">
          {{ day.refDate | date('ll') }}
        </h4>
        <ul>
          <ActivityLogsItem
            v-for="action in day.actions"
            :key="action.id"
            :action="action"
          />
        </ul>
      </div>
    </div>

    <IdxBtn
      v-if="!loadedAllActions"
      :class="['block mx-auto', { 'is-loading': loading }]"
      @click="loadActions"
    >
      {{ $t('button-load-more') }}
    </IdxBtn>
  </div>
  <p v-else>
    <em class="has-text-grey">{{ $t('no-log-to-display') }}</em>
  </p>
</template>

<script>
import { Project } from 'cytomine-client';
import dayjs from 'dayjs';
import debounce from 'lodash/debounce';

import ActivityLogsItem from './ActivityLogsItem.vue';

export default {
  name: 'ActivityLogs',
  components: { ActivityLogsItem },
  props: {
    startDate: {
      type: Number,
      default: undefined,
    },
    endDate: {
      type: Number,
      default: undefined,
    },
    idUser: {
      type: Number,
      default: undefined,
    },
    project: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      loading: true,
      error: false,

      offset: 0,
      nbActionsPerPage: 50,
      loadedAllActions: false,

      actions: [],
    };
  },
  computed: {
    params() {
      return {
        user: this.idUser,
        startDate: this.startDate,
        endDate: this.endDate,
        project: this.project ? this.project.id : null,
        fullData: true,
      };
    },
    formattedActions() {
      const results = [];
      let lastDate = null;
      let idxMonths = -1;
      let idxDays = -1;
      for (let i = 0; i < this.actions.length; i++) {
        const action = this.actions[i];
        const date = dayjs(Number(action.created));
        if (!lastDate || !date.isSame(lastDate, 'day')) {
          if (!lastDate || !date.isSame(lastDate, 'month')) {
            results.push({
              refDate: Number(action.created),
              days: [],
            });
            idxMonths++;
            idxDays = -1;
          }

          results[idxMonths].days.push({
            refDate: Number(action.created),
            actions: [action],
          });
          idxDays++;
        } else {
          results[idxMonths].days[idxDays].actions.push(action);
        }
        lastDate = date;
      }

      return results;
    },
  },
  watch: {
    params() {
      this.loadActions(false);
    },
  },
  async created() {
    try {
      await this.loadActions();
    } catch (error) {
      console.log(error);
      this.error = true;
    }

    this.loading = false;
  },
  methods: {
    scrollHandler: debounce(function () {
      const scrollBlock = this.$refs.listActions;
      const bottom =
        scrollBlock.scrollTop + scrollBlock.clientHeight ===
        scrollBlock.scrollHeight;

      if (bottom && !this.loadedAllActions) {
        this.loadActions();
      }
    }, 100),

    async loadActions(append = true) {
      this.loading = true;
      if (!append) {
        this.actions = [];
        this.offset = 0;
        this.loadedAllActions = false;
      }

      try {
        const data = await Project.fetchCommandHistory({
          max: this.nbActionsPerPage,
          offset: this.offset,
          ...this.params,
        });

        this.actions.push(...data);
        this.offset += this.nbActionsPerPage;

        if (data.length < this.nbActionsPerPage) {
          this.loadedAllActions = true;
        }
      } catch (error) {
        console.log(error);
        this.error = true;
      }

      this.loading = false;
    },
  },
};
</script>
